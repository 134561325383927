import {useEffect, useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {enqueueSnackbar} from "notistack";

import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import {
    Box,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography
} from "@mui/material";

import {useGetAllUserGroupsQuery, UserGroupInfo} from "../api/usergroups";
import {useGetUserInfoQuery, UserInfo, useUpdateProfileMutation} from "../api/user";
import {ErrorAlert} from "../components/ErrorAlert";
import {t} from "../i18n/i18n";

const formInitialState: UserInfo = {
    id: null,
    status: 'ACTIVE',
    firstName: '',
    fullName: '',
    birthDate: '',
    email: '',
    bpIdSub: '',
    handicapMin: 0,
    userGroup: undefined,
    additionalUserGroups: [],
    admin: false,
    readTermsOfUse: false,
    profileComplete: false,
    created: '',
    lastLogin: '',
    lastUpdatedNif: ''
}

export const ProfilePage = () => {
    const { data, isSuccess, isFetching, isLoading, isError, error} = useGetUserInfoQuery(undefined, { refetchOnMountOrArgChange: 60 });
    const [updateProfile, updateStatus] = useUpdateProfileMutation();
    const {data: groupsData, isSuccess: groupsSuccess, isLoading: groupsLoading, isError: groupsIsError, error: groupsError} =
        useGetAllUserGroupsQuery();
    const [formData, setFormData] = useState<UserInfo>(formInitialState);
    const [saving, setSaving] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isFetching && isSuccess) {
            setFormData(data);
        }
    }, [isFetching, isSuccess, data]);

    if (data?.status === 'NOT_CONFIRMED') {
        return <Navigate to={"/login_confirm_user"} />;
    }

    const lastLogin = isSuccess ? new Date(data.lastLogin) : '';
    const additionalGroupIds = isSuccess ? data.additionalUserGroups.map<number>(g => g.id) : [];
    const filteredGroups = groupsSuccess
        ? groupsData.filter(g => additionalGroupIds.indexOf(g.id) === -1)
        : [];
    filteredGroups.sort((a, b) => a.title.localeCompare(b.title, 'no'));


    const handleSave = () => {
        setSaving(true);

        updateProfile(formData).unwrap().then(() => {
            setSaving(false);
            setFormData(formInitialState);
            enqueueSnackbar(t('profile.message.updated'), { variant: 'success' });
            navigate('/login_perform');
        }).catch(() => {
            setSaving(false);
        });
    }

    return (
        <Container sx={{my: {xs: 0, md: 0}, px: {xs: 2, md: 3}}}>
            {isError && <ErrorAlert title={t('apiError.getUserInfo')} error={error} />}
            {groupsIsError && <ErrorAlert title={t('apiError.getGroupsInfo')} error={groupsError} />}
            {updateStatus.isError && <ErrorAlert title={t('apiError.updateProfile')} error={updateStatus.error} />}

            {(isLoading || groupsLoading) && <CircularProgress />}

            <h1>{t('profile.title')}</h1>

            <Typography>
                {t('profile.ingress')}
            </Typography>

            {isSuccess && groupsSuccess && <Box component={'form'}
                 sx={{
                     display: 'grid',
                     gridTemplateColumns: {sm: '1fr'},
                     gap: 2
                 }}
                 noValidate
                 autoComplete={'off'}>
                <Typography>{t('profile.lastLogin')}: {lastLogin.toLocaleString("nb-NO")}</Typography>
                <FormControl fullWidth>
                    <InputLabel htmlFor={'profile-name'}>{t('profile.form.name')}</InputLabel>
                    <OutlinedInput
                        id={'profile-name'}
                        disabled
                        label={t('profile.form.name')}
                        value={data.fullName}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor={'profile-birthdate'}>{t('profile.form.birthdate')}</InputLabel>
                    <OutlinedInput
                        id={'profile-birthdate'}
                        disabled
                        label={t('profile.form.birthdate')}
                        value={data.birthDate ?? '-'}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor={'profile-email'}>{t('profile.form.email')}</InputLabel>
                    <OutlinedInput
                        id={'profile-email'}
                        disabled
                        label={t('profile.form.email')}
                        value={data.email}
                    />
                </FormControl>
                <FormControl required error={formData.userGroup === null}>
                    <InputLabel id={'profile-group-label'}>{t('profile.form.region')}</InputLabel>
                    <Select
                        id={'profile-group'}
                        labelId={'profile-group-label'}
                        fullWidth
                        value={formData.userGroup ?? ''}
                        onChange={e => setFormData({
                            ...formData,
                            userGroup: e.target.value as unknown as UserGroupInfo
                        })}
                        renderValue={(selected) => selected.title}
                    >
                        {filteredGroups.map((group) => (
                            <MenuItem
                                key={group.id}
                                value={group as any}
                            >
                                {group.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormGroup>
                    {data.additionalUserGroups.map((aGroup) => (
                        <FormControlLabel
                            key={aGroup.id}
                            disabled
                            control={<Checkbox checked/>}
                            label={aGroup.title}
                        />
                    ))}
                </FormGroup>
                <FormGroup>
                    {data.userRoles.map((userRole) => (
                        <FormControlLabel
                            key={userRole.id}
                            control={<Checkbox checked/>}
                            label={userRole.role + (userRole.userGroup !== undefined && userRole.userGroup !== null ? ': ' + userRole.userGroup.title : '')}
                        />
                    ))}
                </FormGroup>
                <LoadingButton
                    disabled={formData.userGroup === undefined || formData.userGroup === null}
                    loading={saving}
                    variant={'contained'}
                    color={'success'}
                    size={'large'}
                    endIcon={<SendIcon/>}
                    onClick={handleSave}
                >
                    {t('generic.button.save')}
                </LoadingButton>
            </Box>}
        </Container>
    );
};
