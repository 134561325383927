import {CircularProgress} from "@mui/material";

import {useGetSystemInfoQuery} from "../../api/info";
import {ErrorAlert} from "../../components/ErrorAlert";
import {t} from "../../i18n/i18n";

export const SystemInfo = () => {
    const {data, isError, error, isLoading, isSuccess} = useGetSystemInfoQuery();

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.systemInfo')} error={error} />}

            {isLoading && <CircularProgress />}

            {isSuccess && (
                <ul>
                    <li>{t('admin.systeminfo.version.backend', {
                        version: data.buildProperties.version,
                        timestamp: new Date(data.buildProperties.time).toLocaleString("nb-NO")
                    })}</li>
                    <li>{t('admin.systeminfo.version.frontend', {
                        version: '-',
                        timestamp: '-'
                    })}</li>
                </ul>
            )}
        </>
    );
};
