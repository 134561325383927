import {useEffect, useRef, useState} from "react";

import {CircularProgress} from "@mui/material";

export const LoginOngoingPage = () => {
    const [fetchingUrl, setFetchingUrl] = useState<boolean>(false);
    const calledOnce = useRef(false);

    const fetchUrl = async () => {
        const response = await fetch('/oauth2/authorization/nif');
        if (response.ok) {
            const {redirectUrl} = await (response.json());
            window.location.replace(redirectUrl);
        } else {
            window.location.replace('/login?error')
        }
    }

    useEffect(() => {
        if (calledOnce.current) {
            return;
        }

        if (!fetchingUrl) {
            calledOnce.current = true;
            setFetchingUrl(true);
            fetchUrl()
                .catch(console.error);
        }
    }, [fetchingUrl]);


    return <CircularProgress/>;
};
