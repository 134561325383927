import api from './index';

export interface UserGroupInfo {
    id: number;
    title: string;
    shortname: string;
    userSelectable?: boolean;
    members?: number;
}

const usergroup = api.injectEndpoints({
    endpoints: (build) => ({
        getAllUserGroups: build.query<UserGroupInfo[], void>({
            query: () => 'usergroup/all',
            providesTags: (result) => result
                ? [...result.map(({id}) => ({type: 'UserGroup' as const, id})), {type: 'UserGroup', id: 'LIST'}]
                : [{type: 'UserGroup', id: 'LIST'}]
        }),
        getAllUserGroupsAdmin: build.query<UserGroupInfo[], void>({
            query: () => 'admin/usergroup/all',
            providesTags: (result) => result
                ? [...result.map(({id}) => ({type: 'UserGroupAdmin' as const, id})), {
                    type: 'UserGroupAdmin',
                    id: 'LIST'
                }]
                : [{type: 'UserGroupAdmin', id: 'LIST'}]
        }),
        addUserGroup: build.mutation<UserGroupInfo, Partial<UserGroupInfo>>({
            query: (body) => ({
                url: 'admin/usergroup',
                method: 'POST',
                body
            }),
            invalidatesTags: ['UserGroup', 'UserGroupAdmin']
        }),
        updateUserGroup: build.mutation<void, Pick<UserGroupInfo, 'id'> & Partial<UserGroupInfo>>({
            query: ({id, ...patch}) => ({
                url: `admin/usergroup/${id}`,
                method: 'PUT',
                body: patch,
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'UserGroup', id}, {type: 'UserGroupAdmin', id}]
        })
    }),
    overrideExisting: false
});

export const {
    useGetAllUserGroupsQuery,
    useGetAllUserGroupsAdminQuery, useAddUserGroupMutation, useUpdateUserGroupMutation
} = usergroup;
