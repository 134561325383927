import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";

import {Alert, AlertTitle} from "@mui/material";

interface BackendError {
    status: number;
    error: string;
    path: string;
    message: string;
    timestamp: string;
}

interface Props {
    title: string;
    error?: FetchBaseQueryError | SerializedError;
}

export const ErrorAlert = ({
    title,
    error,
}: Props) => {
    if (error === undefined) {
        return null;
    }

    const isFetchBaseQueryError = (errorData: FetchBaseQueryError | SerializedError): errorData is FetchBaseQueryError => {
        return !!(errorData as FetchBaseQueryError).status;
    }

    const info: JSX.Element[] = [];

    if (isFetchBaseQueryError(error)) {
        if (typeof(error.status) === 'number') {
            const errorData = error.data as BackendError;

            info.push(
                <dd key={'status'}>Status: {error.status}</dd>,
                <dd key={'path'}>Path: {errorData.path}</dd>,
                <dd key={'error'}>Error: {errorData.error}</dd>,
                <dd key={'message'}>Message: {errorData.message}</dd>
            );
        } else {
            info.push(<dd key={'status'}>Status: {error.status}</dd>);
            error.status === 'PARSING_ERROR' && info.push(<dd key={'status'}>Status: {error.originalStatus}</dd>);
            (error.status === 'PARSING_ERROR' || error.status === 'FETCH_ERROR' || error.status === 'CUSTOM_ERROR')
                && info.push(<dd key={'error'}>Error: {error.error}</dd>);
        }
    } else {
        error.code && info.push(<dd key={'code'}>Code: {error.code}</dd>);
        error.name && info.push(<dd key={'name'}>Name: {error.name}</dd>);
        error.message && info.push(<dd key={'message'}>Message: {error.message}</dd>);
    }

    return (
        <Alert severity={'error'}>
            <AlertTitle>{title}</AlertTitle>
            <dl>
                {info}
            </dl>
        </Alert>
    );
};
