import {createContext, useEffect, useState} from "react";

export const authContext = createContext({auth: {} as AuthState | null, setAuthData: (data: AuthData | null) => {}});

export interface AuthData {
    accountId: string;
    firstname: string;
    admin: boolean;
    profileComplete: boolean;
    hasConfirmed: boolean;
}

interface AuthState {
    loading: boolean;
    data: AuthData | null;
}

const AuthProvider = ({children}: { children: JSX.Element }) => {
    const [auth, setAuth] = useState<AuthState>({loading: true, data: null});

    const setAuthData = (data: AuthData | null) => {
        setAuth({loading: false, data: data});
    };

    useEffect(() => {
        const jsondata = window.localStorage.getItem('authData');
        if (jsondata !== null) {
            setAuth({loading: false, data: JSON.parse(jsondata)});
        }
    }, []);

    useEffect(() => {
        if (!auth.loading || auth.data !== null) {
            if (auth.data !== null) {
                window.localStorage.setItem('authData', JSON.stringify(auth.data));
            } else if (window.localStorage.getItem('authData') !== null) {
                window.localStorage.removeItem('authData');
            }
        }
    }, [auth]);

    return (
        <authContext.Provider value={{auth, setAuthData}}>
            {children}
        </authContext.Provider>
    );
};

export default AuthProvider;
