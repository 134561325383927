import {useContext, useEffect, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment/moment";

import {Avatar, Container, List, ListItemAvatar, ListItemButton, ListItemText, Skeleton} from "@mui/material";
import QuizIcon from "@mui/icons-material/School";

import {useGetResultsQuery} from "../api/results";
import {authContext} from "../components/AuthProvider";
import {ErrorAlert} from "../components/ErrorAlert";
import {handleUnauthorizedError} from "../util/UnauthorizedErrorHandlerUtil";
import {t} from "../i18n/i18n";

export const ResultsPage = () => {
    const {data, isSuccess, isLoading, isError, error} = useGetResultsQuery(undefined, {
        refetchOnMountOrArgChange: 180
    });
    const authCtx = useRef(useContext(authContext));
    const navigate = useRef(useNavigate());

    useEffect(() => {
        if (isError) {
            handleUnauthorizedError(error, navigate.current, authCtx.current.setAuthData)
        }
    }, [isError, error, navigate, authCtx]);

    return (
        <Container sx={{my: {xs: 0, md: 0}, px: {xs: 2, md: 3}}}>
            {isError && <ErrorAlert title={t('apiError.resultList')} error={error} />}

            <h1>{t('results.title')}</h1>

            {isLoading && <Skeleton height={'80px'}/>}

            {isSuccess && (data.length === 0 ? <p>{t('results.noResults')}</p> :
                <List
                    sx={{width: '100%', maxWidth: '100vw', bgcolor: 'background.paper'}}
                >
                    {data.map((row) => {
                        return (
                            <ListItemButton
                                key={'result' + row.executionId}
                                to={'/results/' + row.executionId}
                                component={Link}
                            >
                                <ListItemAvatar>
                                    <Avatar sx={{bgcolor: row.result === 'PASSED' ? 'green' : 'red'}}>
                                        <QuizIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={row.title} secondary={
                                    <>
                                        {t('result.status.' + row.result)}<br/>
                                        {moment(row.start).format('YYYY-MM-DD HH:mm')}
                                    </>
                                }/>
                            </ListItemButton>
                        );
                    })}
                </List>
            )}
        </Container>
    );
};
