import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

const index = createApi({
    baseQuery: fetchBaseQuery({baseUrl: '/api'}),
    tagTypes: [
        'Images',
        'LatestEventLogs',
        'LatestNews', 'MyProfile', 'News',
        'Question', 'Quiz', 'Subject', 'SubjectStats',
        'UserGroup', 'UserGroupAdmin', 'User', 'UserRole', 'UserSummary'
    ],
    endpoints: () => ({}),
});

export default index;
