import {useContext, useEffect, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment/moment";

import {
    Avatar,
    CircularProgress,
    Container,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography
} from "@mui/material";
import QuizIcon from "@mui/icons-material/School";

import {useGetQuizInvitesQuery} from "../api/quizinvite";
import {handleUnauthorizedError} from "../util/UnauthorizedErrorHandlerUtil";
import {authContext} from "../components/AuthProvider";
import {ErrorAlert} from "../components/ErrorAlert";
import {t} from "../i18n/i18n";

export const AvailableQuizesPage = () => {
    const {data, isSuccess, isLoading, isError, error} = useGetQuizInvitesQuery(undefined, {
        refetchOnMountOrArgChange: 180
    });
    const authCtx = useRef(useContext(authContext));
    const navigate = useRef(useNavigate());

    useEffect(() => {
        if (isError) {
            handleUnauthorizedError(error, navigate.current, authCtx.current.setAuthData)
        }
    }, [isError, error, navigate, authCtx]);

    return (
        <Container sx={{my: {xs: 0, md: 0}, px: {xs: 2, md: 3}}}>
            {isError && <ErrorAlert title={t('apiError.quizAvailableList')} error={error} />}

            <h1>{t('availableQuizes.title')}</h1>

            {isLoading && <CircularProgress />}

            {isSuccess && <List
                    sx={{width: '100%', maxWidth: '100vw', bgcolor: 'background.paper'}}
                >
                    {!isSuccess ? <CircularProgress/> :
                        data.length > 0 ?
                            data.map(({id, title, numberOfQuestions, durationMinutes, start, end, attemptsLeft}) => {
                                let subtitle = moment(start).format('YYYY-MM-DD HH:mm');
                                if (end !== null && end.length > 0) {
                                    subtitle += ' - ' + moment(end).format('YYYY-MM-DD HH:mm');
                                } else {
                                    subtitle += ' ->';
                                }

                                if (attemptsLeft !== undefined && attemptsLeft !== null) {
                                    subtitle += ', ' + attemptsLeft + ' ' + t('availableQuizes.attemptsLeft');
                                }

                                subtitle += ' (';
                                subtitle += numberOfQuestions + ' ' + t('availableQuizes.questions') + ', '
                                subtitle += durationMinutes + ' ' + t('availableQuizes.minutes');
                                subtitle += ')';

                                return (
                                    <ListItemButton
                                        key={'frontQuiz' + id}
                                        to={'/quizinfo/' + id}
                                        component={Link}
                                        disabled={0 === attemptsLeft}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <QuizIcon/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={title}
                                            secondary={subtitle}
                                        />
                                    </ListItemButton>
                                );
                            })
                            : <Typography>{t('availableQuizes.noneAvailable')}</Typography>
                    }
                </List>
            }
        </Container>
    );
};
