import {useContext, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";

import {CircularProgress} from "@mui/material";

import {useGetUserInfoQuery} from "../api/user";
import {authContext} from "../components/AuthProvider";
import {ErrorAlert} from "../components/ErrorAlert";
import {t} from "../i18n/i18n";

export const LoginSuccessPage = () => {
    const navigate = useRef(useNavigate());
    const {data, isSuccess, isError, error} = useGetUserInfoQuery();
    const authCtx = useRef(useContext(authContext));

    useEffect(() => {
        if (isSuccess) {
            authCtx.current.setAuthData({
                accountId: data.bpIdSub,
                firstname: data.firstName,
                admin: data.admin,
                profileComplete: data.profileComplete,
                hasConfirmed: data.status !== 'NOT_CONFIRMED'
            });
            navigate.current('/');
        }
    }, [isSuccess, data, navigate, authCtx]);

    return (
        <>
            {isError && <ErrorAlert title={t('apiError.getUserInfo')} error={error} />}

            <CircularProgress/>
        </>
    );
};
