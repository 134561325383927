import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {NavigateFunction} from "react-router-dom";
import {AuthData} from "../components/AuthProvider";

export const handleUnauthorizedError = (error: FetchBaseQueryError | SerializedError, navigate: NavigateFunction, setAuthData: (data: (AuthData | null)) => void) => {
    if ('status' in error && (error.status === 401 || error.status === "PARSING_ERROR")) {
        setAuthData(null);
        navigate('/login_perform');
    }
}